<template>
  <div class="s_bf5">
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"' @click='active = 1'>扫码界面</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"' @click='onRefresh();active = 2'>物流码明细</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-form @submit="onSubmit">
        <!-- <van-field v-model="form.warehousing_sn" name="warehousing_sn" label="入库单号" placeholder="入库单号" readonly :rules="[{ required: true, message: '入库单号不能为空' }]"></van-field> -->
        <!-- <van-field v-model="form.warehouse_name" is-link  readonly name="warehouse_name" label="退货仓库" placeholder="请选择退货仓库" @click="()=>{if(isDisabled){return} warehouseShow = true}"/> -->
        <!-- <van-field v-model="form.product_batch_name" is-link readonly name="picker" label="生产批次" placeholder="请选择生产批次" @click="batchShow = true" /> -->
        <div class="m-t-10 s_bf p-l-15 p-r-15 p-t-15">
          <div class="s_flex_bian_c p-b-15">
            <img class="width-80 height-80 tw-rounded-sm" :src="info.oss_domain+goodsItem.product_image" alt="">
            <div class="width72">
              <p class="fz-15 s_omit2">{{goodsItem.product_name}}</p>
              <p class="tw-text-gray-400 m-t-7">规格：{{goodsItem.product_sku_name || '单规格'}}</p>
              <p class="tw-text-gray-400">编号：{{goodsItem.product_sn}}</p>
            </div>
          </div>
          <van-divider :style="{ margin: '0' }" />
        </div>
        <van-field name="radio" label="扫码类型">
          <template #input>
            <van-radio-group v-model="form.scan_type" direction="horizontal" checked-color='#5B9DFF' @click='typeCheck = true'>
              <van-radio :name="1">扫码出库</van-radio>
              <van-radio :name="2">扫码回退</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.code_sn" name="code_sn" label="二维码"  autocomplete="off" placeholder="请输入二维码" @keypress='scanCode' required :rules="[{ required: true, message: '二维码不能为空' }]">
          <template #button>
            <van-button style="width:66px" block color='#5B9DFF' size="small" type="primary" native-type="submit">{{form.scan_type == 1 ? '出库':'回退'}}</van-button>
          </template>
        </van-field>
        <van-field v-model="form.scan_num" readonly name="scan_num" label="扫码数量" placeholder="扫码数量" v-if='showResult'/>
        <van-field v-model="form.real_stock_num" readonly name="real_stock_num" label="商品数量" placeholder="商品数量" v-if='showResult'/>
        <div class="fz-14 p-15 s_bf5" v-if='showResult && !typeCheck'>
          <p class="width100">
            <span v-if='form.scan_type == 1'>扫码结果：{{resultCode == 0 ? '出库成功！':'出库失败！'}}</span>
            <span v-else>扫码结果：{{resultCode == 0 ? '回退成功！':'回退失败！'}}</span>
          </p>
          <p class="width100 m-t-15" v-if='resultCode != 0'>{{resultMsg}}</p>
        </div>
      </van-form>
    </div>
    <div class="m-t-10" v-if='active == 2'>
      <van-search
        v-model="search.keyword"
        show-action
        placeholder="请输入物流码进行搜索"
        @search="onSearch"
      >
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="p-15">
        <div class="s_flex_bian_c s_bf5 height-40 tw-border tw-border-gray-200 tw-border-solid tw-bg-gray-300 tw-bg-opacity-30" v-if='list.length'>
          <p class="width33 s_flex_center">物流码</p>
          <p class="width33 s_flex_center">码类型</p>
          <p class="width33 s_flex_center">商品数量</p>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model:loading="loading" :finished="finished" :finished-text="list.length?'没有更多了':''" @load="onLoad">
            <div class="s_flex_bian_c height-40 tw-border tw-border-gray-200 tw-border-solid tw-border-t-0 s_bf" v-for="(item,index) in list" :key='index'>
              <p class="tw-text-center width33">{{item.code_sn}}</p>
              <p class="tw-text-center width33">{{item.code_type_text}}</p>
              <p class="tw-text-center width33">{{item.code_num}}</p>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <van-empty v-if='!list.length' :image="require('@/assets/image/empty.png')" description="暂无物流码" />
    </div>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { getOutWarehouseProductScans,setOutWarehouseScan } from '@/api/outWarehouse.js';
  import {useList} from '@/hooks/useListHandle'
  import store from '@/store'
  let defForm = {
    out_warehousing_sn: '',
    code_sn: '',
    out_warehouse_product_id: '',
    scan_type: 1,
    scan_num:0,
    real_stock_num:0
  };
  export default {
    props:{
      goodsItem:{
        type:Object
      },
      sn:{
        type:String
      }
    },
    setup(props, { emit }) {
      const form = ref({ ...defForm });
      const state = reactive({
        active:1,
        list:[],
        showResult:false, // 是否显示扫码结果
        resultCode:'', //扫码结果code
        resultMsg:'', // 扫码结果提示语
        info:store.state.user.info,
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
        typeCheck:false, //扫码类型切换，暂时隐藏入库成功/回退成功
      })
      const { onRefresh, search,finished, refreshing, onLoad, list, loading } = useList(getOutWarehouseProductScans,{keyword: "",limit: 10,page: 1});

      //扫码
      const audios=ref('')
      const onSubmit = async(values) => {
        if(!form.value.code_sn){
          Toast.fail('请输入二维码');
          return
        }

        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setOutWarehouseScan({...form.value,out_warehousing_sn:props.sn,out_warehouse_product_id:props.goodsItem.out_warehouse_product_id}).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          Toast.success(result.msg);
          audios.value.pass()
          form.value.real_stock_num = result.data.real_stock_num;
          form.value.scan_num = result.data.scan_num
          emit("scanok");
				}else{
          Toast.fail(result.msg);
          audios.value.error()
				}
        state.resultCode = result.code
        state.resultMsg = result.msg
        state.showResult = true
        form.value.code_sn = ''
        state.typeCheck = false
      };

      onMounted(() => {
        search.value.out_warehousing_sn = props.sn
        search.value.out_warehouse_product_id = props.goodsItem.out_warehouse_product_id
      })
      return {
        ...toRefs(state),
        form,
        onSubmit,
        onRefresh,
        finished,
        loading,
        refreshing,
        list,
        search,
        onLoad,
        audios
      };
    }
  };
</script>
