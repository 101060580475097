<template>
  <div>
    <van-form @submit="confirmOut">
      <van-field v-model="form.out_warehousing_sn" is-link readonly name="out_warehousing_sn" label="出库单" placeholder="请选择出库单" required :rules="[{ required: true, message: '请选择出库单' }]" @click="orderShow = true" v-if='!form.out_warehousing_sn'/>
      <div class="s_bf p-l-15 p-r-15 p-t-15 " @click="()=>{if(isDisabled){return} orderShow = true}" v-else>
        <div class="p-b-15 s_flex_bian_c">
          <div class="width90">
            <p class="fz-15">{{form.out_warehousing_sn}}</p>
            <!-- <p class="tw-text-gray-400 m-t-7">ERP单号：2021081912234504011234</p> -->
            <p class="tw-text-gray-400 m-t-5">出库仓库：{{form.warehouse_name}}</p>
          </div>
          <van-icon name="arrow" size='16px' color='#969799'/>
        </div>
        <van-divider :style="{ margin: '0',width:'100%' }" />
      </div>
      <van-field name="use_product_batch" label="物流配送">
        <template #input>
          <van-switch @change="form.delivery_type = showType ? 1 : 2" active-color='#5B9DFF' v-model="showType" size="20" />
        </template>
      </van-field>
      <van-field v-model="form.logistics_name" is-link readonly name="logistics_name" label="物流公司" placeholder="请选择物流公司" @click="logisticsShow = true" v-if='form.delivery_type == 1' />
      <van-field v-model="form.logistics_code" name="logistics_code" label="物流单号" required :rules="[{ required: true, message: '请输入物流单号' }]" placeholder="请输入物流单号" v-if='form.delivery_type == 1' />
      <div class="tw-fixed tw-left-0 tw-bottom-0 s_flex_center width100 p-15">
        <van-button block round color="linear-gradient(to right, #53A1FF, #68D0FF)" native-type="submit">确定出库</van-button>
      </div>
    </van-form>
    <div class="m-t-10" v-if='form.out_warehousing_sn'>
      <div class="p-l-10 p-r-10" v-if='list.length'>
        <div class="s_flex_bian_c p-10 s_bf m-b-10 tw-rounded-sm" v-for="(item,index) in list" :key='index'>
          <img class="width-75 height-75 tw-rounded-sm" :src="info.oss_domain+item.product_image" alt="">
          <div class="width73">
            <p class="fz-15 s_omit">{{item.product_name}}</p>
            <div class="tw-flex tw-items-end m-t-5">
              <div class="width70">
                <p class="tw-text-gray-400">规格：{{item.product_sku_name || '单规格'}}</p>
                <p class="tw-text-gray-400">商品编号：{{item.product_sn}}</p>
                <p class="tw-text-gray-400">出货数量：{{item.out_warehousing_real_num}}/{{item.out_warehousing_num}}</p>
              </div>
              <div class="width30"><van-button color='#5B9DFF' size='mini' block @click='goodsItem=item;scanCodeShow=true'>出库</van-button></div>
            </div>
          </div>
        </div>
        <p class="tw-text-center tw-text-gray-400 fz-14 p-t-10 p-b-10">我也是有底线的~</p>
      </div>
      <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无商品" />
    </div>
    <!-- 选择订单 -->
    <van-popup v-model:show="orderShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectOrder orderTpye='out' @select='orderConfirm'></selectOrder>
    </van-popup>
    <!-- 选择物流公司 -->
    <van-popup v-model:show="logisticsShow" position="bottom" round safe-area-inset-bottom :style="{ maxHeight: '80%'}">
      <selectLogistics @cancel='logisticsShow = false' @confirm='logisticsConfirm'></selectLogistics>
    </van-popup>
    <!-- 扫码 -->
    <van-popup v-model:show="scanCodeShow" position="bottom" round safe-area-inset-bottom closeable :style="{ height: '95%',backgroundColor:'#f5f5f5'}" @close='getMore'>
      <byOrderCode :sn='form.out_warehousing_sn' :goodsItem='goodsItem' @scanok='isDisabled=true'></byOrderCode>
    </van-popup>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast,Dialog } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { checkOutWarehouse,setOutWarehouse,getOutWarehouseMore} from '@/api/outWarehouse.js';
  import { SplitArray } from "@/utils/util";
  import store from '@/store'
  import byOrderCode from './byOrderCode.vue'
  let defForm = {
    out_warehousing_sn:'',
    out_type:1,
    delivery_type:2,
    logistics_name:'',
    logistics_code:'',
  };
  export default {
    components: {byOrderCode},
    setup() {
      const form = ref({ ...defForm });
      const state = reactive({
        list:[],
        orderShow:false, //是否显示订单选择
        logisticsShow:false, //是否显示物流公司选择
        showType:false, //物流配送开关
        info:store.state.user.info,
        scanCodeShow:false,
        isDisabled:false, //是否禁用某些选项
        goodsItem:{}
      })
      const audios=ref('')
      const $route = inject("$route");
      const $router = inject("$router");
      // 订单选择
      const orderConfirm = (e) =>{
        form.value.out_warehousing_sn = e.out_warehousing_sn
        form.value.warehouse_name = e.warehouse_position_name
        getMore();
        state.orderShow = false
      }
      //获取详情
      const getMore = async() =>{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await getOutWarehouseMore(form.value.out_warehousing_sn).then(res => res.data).catch(error => error);
        Toast.clear();
        if (iscode(result)) {
          state.list = result.data.product
				}else{
          Toast.fail(result.msg);
				}
      }
      // 物流选择
      const logisticsConfirm = (e) =>{
        form.value.logistics_name = e.name
        state.logisticsShow = false
      }
      //提交检查
      const confirmOut = async(values) => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await checkOutWarehouse(form.value.out_warehousing_sn).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          audios.value.pass()
          outWarehouseSubmit(result.data.is_all);
        }else{
          audios.value.error()
          Toast.fail(result.msg);
        }
      };
      //出库
      const outWarehouseSubmit = (out_type) =>{
        Dialog.confirm({
          title: out_type == 1 ? '确定出货？':'确定部分出库？',
          message:out_type == 1 ? '' : '您的商品未全部出货，确定后未出货的商品将生成一张新出货单，后续可继续发货',
          confirmButtonColor:'#5B9DFF',
        }).then(async() => {
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration:0
          });
          let result = await setOutWarehouse({
            out_warehousing_sn:form.value.out_warehousing_sn,
            out_type:out_type,
            delivery_type:form.value.delivery_type,
            logistics_name:form.value.logistics_name,
            logistics_code:form.value.logistics_code,
          }).then(res => res.data).catch(error => error);
          Toast.clear();
          if (iscode(result)) {
              audios.value.pass()
            Dialog.confirm({
              title: '出货成功',
              confirmButtonText:'继续发货',
              confirmButtonColor:'#5B9DFF',
            }).then(async() => {
              state.isDisabled = false;
              form.value.out_warehousing_sn = '';
              form.value.out_type = 1;
              form.value.delivery_type = state.showType ? 1 : 2;
              form.value.logistics_name = '';
              form.value.logistics_code = '';
              state.list = []
         
            }).catch(() => {
              $router.go(-1)
            })
          }else{
            Toast.fail(result.msg);
            audios.value.error()
          }
        }).catch(() => {})
      }
      return {
        ...toRefs(state),
        form,
        confirmOut,
        outWarehouseSubmit,
        orderConfirm,
        logisticsConfirm,
        onSelect,
        audios,
        getMore
      };
    }
  };
</script>
